<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>문의내역 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="title">제목</option>
          <option value="content">내용</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">답변상태{{ isReply }}</p>
        <label>
          <input type="radio" value="" checked v-model="isReply" />전체
        </label>
        <label>
          <input type="radio" :value="false" v-model="isReply" />
          답변대기
        </label>
        <label>
          <input type="radio" :value="true" v-model="isReply" />
          답변완료
        </label>
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label>
          <input type="radio" value="RESERVATION" v-model="category" />
          상품 이용 예약
        </label>
        <label>
          <input type="radio" value="COMPOSITION" v-model="category" />
          상품 구성 문의
        </label>
        <label>
          <input type="radio" value="QUOTATION" v-model="category" />
          견적 문의
        </label>
        <label>
          <input type="radio" value="CONSULT" v-model="category" />
          상담 요청
        </label>
        <label>
          <input type="radio" value="ETC" v-model="category" />
          기타
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one qna">
      <h2 class="bold">문의내역 리스트</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 40%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th scope="col">번호</th>
          <th scope="col">카테고리</th>
          <th scope="col">제목</th>
          <th scope="col">작성자</th>
          <th scope="col">날짜</th>
          <th scope="col">답변상태</th>
        </tr>
        <tr v-for="(data, i) in qnaList" :key="i">
          <td>
            {{
              currentPage == 1 ? total - i : total - (currentPage - 1) * 10 - i
            }}
          </td>
          <td>
            {{
              data.category == "RESERVATION"
                ? "상품 이용 예약"
                : data.category == "COMPOSITION"
                ? "상품 구성 문의"
                : data.category == "QUOTATION"
                ? "견적 문의"
                : data.category == "CONSULT"
                ? "상담 요청"
                : data.category == "ETC"
                ? "기타"
                : data.category
            }}
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>{{ data.username }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td
            :class="{
              waiting: data.isReply == false,
              done: data.isReply == true,
            }"
          >
            {{ data.isReply == true ? "답변완료" : "답변대기" }}
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />

      <div class="pagination" v-if="qnaList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchQnAList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "qnamanage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      qnaList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
      isReply: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "6");
  },
  mounted() {
    this.getQnaList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.isReply = "";
      this.currentPage = 1;
      this.qnaList = [];
      this.total = 0;
      this.getQnaList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getQnaList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQnaList();
    },
    handleMove(id) {
      this.$router.push({ name: "qnareply", query: { id: id } });
    },
    getQnaList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        isReply: this.isReply,
        category: this.category,
      };
      fetchQnAList(params).then((res) => {
        if (res.data.status == 200) {
          // this.qnaList = res.data.data.filter((item) => item.category != "제휴문의");
          this.qnaList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
